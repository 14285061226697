@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  height: 100%;
  margin: 0;
}

.poppins {
  font-family: "Poppins", sans-serif;
}
