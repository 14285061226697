.linear-text {
  background: -webkit-linear-gradient(#3461ff, #6f41b7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linear-text-2 {
  background: -webkit-linear-gradient(#8454eb, #6f41b7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.thing {
  width: 400px;
  height: 400px;
}

@media (max-width: 600px) {
  .thing {
    height: 300px;
    width: 300px;
  }
}
